.btn {
    font-size: calc(var(--grid-size) / 16);
    border-radius: 1vmax;
    padding: 1vmax 2vmax;
    width: 30vmax;
    font-weight: bold;
    background-color: var(--overlay-btn-bg);
    color: var(--overlay-btn-font);
    cursor: pointer;
    border: none;
  }
  
  .btn:hover {
    filter: brightness(80%);
  }
  