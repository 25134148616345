.overlay {
    color: var(--overlay-default-font);
    background-color: var(--overlay-default-bg);
    font-size: calc(var(--grid-size) / 4);
    text-shadow: 4px 3px 0px rgb(41, 41, 41), 9px 8px 0px rgba(0, 0, 0, 0.15);
    /* backdrop-filter: blur(5px); */
}



.visible {
    opacity: 1 !important;
    z-index: 2 !important;
    /* transition-delay: 0s !important; */
}

.win {
    background-color: var(--overlay-win-bg);
    color: var(--overlay-won-font);
}

.tie {
    background-color: var(--overlay-tie-bg);
    color: var(--overlay-tie-font);

}