* {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {

    --main-bg: #faf4eb;

    --grid-color: #283747;
    --grid-first-player-font: #B03A2E;
    --grid-second-player-font: #2874A6;
    --grid-tie-font: #424949;
    --grid-won-font: #17A589;
    --grid-lost-font: #E5E8E8;

    --overlay-default-bg: #5dade2bd;
    --overlay-win-bg: #1ebc608c;
    --overlay-tie-bg: #ee6f1a8c;
    --overlay-font: #ecf0f1;
    --overlay-btn-bg: rgba(116, 116, 116, 0.452);
    --overlay-btn-font: var(--overlay-font);
    --overlay-default-font: var(--overlay-font);
    --overlay-won-font: var(--overlay-font);
    --overlay-tie-font: var(--overlay-font);
}

:root.dark {

    --main-bg: #242929;

    --grid-color: #454C52;
    --grid-first-player-font: #FF5630;
    --grid-second-player-font: #00B8D9;
    --grid-tie-font: #424949;
    --grid-won-font: #17A589;
    --grid-lost-font: #E5E8E8;

    --overlay-default-bg: #181818e4;
    --overlay-win-bg: var(--overlay-default-bg);
    --overlay-tie-bg: var(--overlay-default-bg);
    --overlay-font: #ecf0f1;
    --overlay-btn-bg: #263238;
    --overlay-btn-font: #F5F5F5;
    --overlay-default-font: #4FC3F7;
    --overlay-won-font: #00C853;
    --overlay-tie-font: #E64A19;
}



html,
body {
    overscroll-behavior-y: none;
    position: relative;
    overflow: hidden;
}

body {
    background: var(--main-bg);
}


.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}